@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.happy-customer-slides .slick-list {
  overflow: visible;
  overflow-x: clip;
  padding-left: 100px;
  margin-left: -50px;
}

.happy-customer-slides .slick-list .slick-track {
  margin-left: -100px
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  body {
    width: -webkit-fill-available;
  }
}