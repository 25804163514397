.app {
    font-family: "Roboto Slab";
}

.slick-dots li button:before {
    font-size: 10px !important;
    color: #ff5d22 !important;
}

:where(.css-dev-only-do-not-override-17seli4).ant-carousel .slick-dots li button::after {
    display: none !important;
}