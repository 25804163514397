.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.ant-skeleton-avatar {
  width: 90px !important;
  height: 90px !important;
}

.custom-slider-container {
  width: 100%;
  padding-bottom: 60px;
  /* Adjust this to fit the space for dots and arrows */
}

// .slider-item {
//   padding: 10px;
//   /* Adjust the padding to manage the gap between items */
// }

// .slick-dots {
//   bottom: -30px;
//   /* Adjust to position the dots */
// }

// .slick-arrow {
//   z-index: 1;
//   background-color: rgba(0, 0, 0, 0.5);
//   border-radius: 50%;
//   width: 30px;
//   height: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .slick-prev {
//   left: 10px;
//   /* Adjust left position */
// }

// .slick-next {
//   right: 10px;
//   /* Adjust right position */
// }
