.themeTextArea {
  resize: none;
  padding: 17px;
  padding-left: 24px;
  border-radius: 12px;
  border: 1px solid #ffffff;
  background: #ffffff99;
}

.themeInput {
  padding: 17px;
  padding-left: 24px;
  border-radius: 12px;
  border: 1px solid #ffffff;
  background: #ffffff99;
}

.themeInput[type="text"],
.themeInput[type="number"],
.themeInput[type="email"],
.themeTextArea {
  color: black !important;
  font-size: 20px;
  font-weight: 700;

  &::placeholder {
    color: #4f4f4f !important;
    transition: 1s;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
}

.themeInput::-webkit-outer-spin-button,
.themeInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.themeInput[type="number"] {
  -moz-appearance: textfield;
}

.themeInput[type="text"]:focus,
.themeInput[type="number"]:focus,
.themeInput[type="email"]:focus,
.themeTextArea:focus {
  border: 1px solid transparent;
  background: #ffffff;
  box-shadow: 0px 5px 24px 0px #0000000f;
  outline: none !important;
}

.themeInput[type="number"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

@media (max-width: 600px) {
  .themeInput[type="text"],
  .themeInput[type="number"],
  .themeInput[type="email"],
  .themeTextArea {
    font-size: 18px;
  }
  .themeInput[type="text"]::placeholder,
  .themeInput[type="number"]::placeholder,
  .themeInput[type="email"]::placeholder,
  .themeTextArea::placeholder {
    font-size: 15px;
  }
}


